console.clear();

const getRandomElement = ( array ) => {
    const randomIndex = Math.floor( Math.random() * array.length );
    return array[ randomIndex ];
};

const rand = ( max = 1, min = 0, { round = false } = {} ) => {
    let n = Math.random() * ( max - min ) + min;
    if( round ){
    return Math.round( n );
    }
    return n;
};

const shuffleArray = ( array ) => {
	array.sort( () => {
		return Math.random() - 0.5;
	} );
};

// --- CLASSES
class Canvas{
    constructor( 
      width = 300, 
      height = width, 
      { 
        canvasElement = document.createElement( 'canvas' ), 
        parent = 'body', 
        append = true,
        context = '2d' 
      } = {}
    ) {
        this.self = canvasElement;
        this.ctx = this.self.getContext( context );
  
        this.update( width, height );
        
        if( append ){    
          document.querySelector( parent ).appendChild( this.self );            
        }
      }
    
      update( width = this.width, height = this.height ) {
        this.dpr = window.devicePixelRatio;
        this.width = width;
        this.height = height;
        this.self.width = width * this.dpr;
        this.self.height = height * this.dpr;
        this.self.style.maxWidth = `${ this.width }px`;
        this.self.style.maxHeight = `${ this.height }px`;
        this.ctx.resetTransform();
        this.ctx.scale( this.dpr, this.dpr );
      }
  
      clear( x = 0, y = 0, width = this.width, height = this.height ) {
        this.ctx.clearRect( x, y, width, height );
      }
  }
  
  
// --- SETTINGS
const root = document.querySelector( ':root' );
let w = window.innerWidth;
let h;
if(w >= 1000){
  h = 160;
}else if(w >= 700){
  h = 80;
}else{
  h = 40;
}

root.style.setProperty("--heightCanvas", h + "px");
const raf = window.requestAnimationFrame;
let canvasNbr = 3;
let canvas = [];
let ctx = [];

for (let i = 0; i < canvasNbr; i++) {
    let parent = '.wrapper' + i;
    canvas[i] = new Canvas( w, h, { parent: parent } )
    ctx[i] = canvas[i].ctx;
    canvas[i].self.classList.add("canvas");
}
    


const options = {
    rows: h / (h/2),
    cols: w / (h/2),
    colors : [
        ['#3aafb9','#D88C9A','#f2d0a9','#F58A07'],
        ['#af8eff','#EA9780','#7fd1b9','#FCAF58'],
        ['#66D7D1','#e1b07e','#e0b0d5','#afe3c0'],
        ['#00afd1','#D5ACA9','#A9DEF9','#F1D302'],
        ['#9CE37D','#87B37A','#A9DEF9','#f08080'],
        ['#909ee3','#ed807e','#c5c5c5','#74b238'],
        ['#ca9ce1','#f37e6f','#EAD5E6','#11c773'],
        ],
    particules: [],
    begin: false
    };

const colorPalett = getRandomElement(options.colors);
for (let i = 0; i < colorPalett.length; i++) {
    root.style.setProperty("--color" + (i+1), colorPalett[i]);
}


// --- CLASS

class Particule{
    constructor(x,y,size){
      this.x = x;
      this.y = y;
      this.size = size;
      this.originalSize = size;
      
      this.color = getRandomElement(colorPalett);
      this.duration = rand( 4000, 1000, { round: true } );
    }

    update( k ) {
        const _k = Math.abs( Math.cos( k / this.duration ) );
        this.size = this.originalSize *  _k;
    }
  }
  
  const initParticules = () => {
    let size = canvas[0].width / options.cols;
    for( let y = 0; y < options.rows; y++ ){
        for( let x = 0; x < options.cols; x++ ){
            options.particules.push( new Particule( x, y, size ) );
        }
    }
    shuffleArray(options.particules);
  };
  
  // --- DRAW
  const draw = ( time = 0 ) => { 
    for (let i = 0; i < canvasNbr; i++) {
        canvas[i].clear();

        for( const p of options.particules){
            const x = p.x * p.originalSize + p.originalSize * 0.5,
                    y = p.y * p.originalSize + p.originalSize * 0.5;
            ctx[i].fillStyle = p.color;
            ctx[i].beginPath();
            ctx[i].arc( x, y, p.size, 0, 2 * Math.PI );
            ctx[i].fill();
            p.update( time );
        }
    }
  };
  
  // --- ANIMATION LOOP
  options.begin = null;
  const tick = ( timestamp ) => {
    if( options.begin === null ){
      options.begin = timestamp;
    }
    
    const time = timestamp - options.begin;
    draw( time );
    raf( tick );
  };
  
  
  initParticules();
  raf( tick );